









































import Vue from 'vue'
import moment from 'moment'
export default Vue.extend({
    props: {
        expert: {type: Object},
        oneChat: {type: Object},
        headimgurl: {type: String},
    },
    computed: {
        current(): any {
        //   console.log(this.$store.state.current)
            return this.$store.state.current
        }
    },
    methods: {
        checkImg(data) {
            this.$emit('checkImg', data)
        },
    },
    filters: {
        dtFormat(timestamp: number) {
            const time = moment(timestamp)
            if(isToday(time)) {
                return time.format('HH:mm')
            }
            return time.format('YYYY年M月D日 HH:mm')
        }
    },
})
function isToday(momentDate: moment.Moment) {
    return momentDate.isSame(moment().startOf('day'), 'd')
}
